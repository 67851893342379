import { Component } from "fq-foundation/Scripts/Component";

new Component().create("ReviewSlider", {
    element: ".f-review-slider",
    sliderElement: ".f-slide__wrapper",
    pagenumber: 0,
    loadReviewsScript: function (callback) {
        var self = this;
        if (!window['POWERREVIEWS']) {
            $.getScript("//ui.powerreviews.com/stable/4.0/ui.js")
                .done(function (script, textStatus) {
                    callback();
                })
                .fail(function (jqxhr, settings, exception) {
                    throw 'Power Reviews could not be loaded';
                    callback();
                });
        }
    },
    init: function () {
        var self = this;
        $.ajax({
            url: "/api/reviews/getreviewsslider",
            data: {
                recordcount: 10,
                minRating: 5,
                maxRating: 5,
                textLimit: 50
            },
            success: function (d) {
                var data = JSON.parse(d);
                data.forEach(function (review) {
                    var rating = review.Stars;
                    var heading = review.Title;
                    var comment = review.Text;
                    var name = review.Author;
                    var quote = self.createQuote(rating, heading, comment, name);
                    var slide = `<div class="f-slide">${quote}</div>`;
                    $(self.element).find('.f-slide__wrapper').append(slide);
                });
                self.initiateSlider();
            },
            error: function (er, a, b) {
                console.log("Error", er, a, b)
            }
        });
    },
    initiateSlider: function () {
        var self = this;
        var Slider = window._componentList["Slider"];
        $(self.element).each(function () {
            var curEl = $(this);
            var options = JSON.parse(JSON.stringify($(this).data('slick-settings')));
            options.responsive.push({
                breakpoint: 319,
                settings: {}
            });
            options.dots = "purple";
            options.arrows = false;
            options.responsive.forEach((slidersetting) => {
                console.log(Number(slidersetting.breakpoint))
                if (Number(slidersetting.breakpoint) >= 767) {
                    slidersetting.settings.arrows = "blue";
                    slidersetting.settings.dots = false;
                } else {
                    slidersetting.settings.arrows = false;
                    slidersetting.settings.dots = "purple";
                }
            });
            console.log(options)
            Slider.setArrowAndDotsColors(options, curEl);
            $(this).find(self.sliderElement).on('init', function (event, slick) {
                $(curEl).closest(self.element).find('.f-slide').css({
                    height: "100%"
                }).find('.columns').css({
                    height: "100%"
                });
                $(window).resize(function () {
                    setTimeout(function () {
                        $(curEl).closest(self.element).find('.f-slide').css({
                            height: "100%"
                        }).find('.columns').css({
                            height: "100%"
                        });
                    }, 100);
                });
            });
            Slider.initialize($(this), $(this).find(self.sliderElement), options);
        });
    },
    createQuote: function (rating, heading, description, name) {
        var self = this;
        var ratings = self.createRatingStars(rating);
        var quote = ratings + `<div class="columns is-centered is-vcentered f-quote">
                        <div class="content-wrap column is-full">
                            <div class="column is-full">
                                <h2 class="a-title">${heading}</h2>
                                <div class="a-description">${description}</div>
                                <p style="" class="">${name}</p>
                            </div>
                        </div>
                    </div>`;
        return quote;
    },
    createRatingStars: function (rating) {
        var stars = `<div class="m-star-rating" data-rating="${rating}">
                        <div class="m-star-rating__wrapper">
                            <svg style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <clipPath id="star-1">
                                        <polygon x="0" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 "/>
                                    </clipPath>
                                    <clipPath id="star-2">
                                        <polygon x="100" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 " transform='translate(100, 0)'/>
                                    </clipPath>
                                    <clipPath id="star-3">
                                        <polygon x="100" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 " transform='translate(200, 0)'/>
                                    </clipPath>
                                    <clipPath id="star-4">
                                        <polygon x="100" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 " transform='translate(300, 0)'/>
                                    </clipPath>
                                    <clipPath id="star-5">
                                        <polygon x="100" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 " transform='translate(400, 0)'/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <!-- Above svg is clipping rectangles and needed in the page atleast once -->
                            <svg version="1.1" shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 100" style="enable-background:new 0 0 500 100;" xml:space="preserve">
                                <g class="nonactive">
                                    <rect x="0" y="0" width="0px" height="100px" clip-path="url(#star-1)"/>
                                    <rect x="100" y="0" width="0px" height="100px" clip-path="url(#star-2)"/>
                                    <rect x="200" y="0" width="0px" height="100px" clip-path="url(#star-3)"/>
                                    <rect x="300" y="0" width="0px" height="100px" clip-path="url(#star-4)"/>
                                    <rect x="400" y="0" width="0px" height="100px" clip-path="url(#star-5)"/>
                                </g>
                                <g class="active">
                                    <rect x="0" y="0" width="0px" height="100px" clip-path="url(#star-1)"/>
                                    <rect x="100" y="0" width="0px" height="100px" clip-path="url(#star-2)"/>
                                    <rect x="200" y="0" width="0px" height="100px" clip-path="url(#star-3)"/>
                                    <rect x="300" y="0" width="0px" height="100px" clip-path="url(#star-4)"/>
                                    <rect x="400" y="0" width="0px" height="100px" clip-path="url(#star-5)"/>
                                </g>
                            </svg>
                        </div>
                    </div>`;
        return stars;
    },
    getNewReviews: function (data, callback) {
        $.ajax({
            url: "https://display.powerreviews.com/m/" + data.merchantId + "/l/" + data.locale + "/product/" + data.pageId + "/reviews",
            data: data,
            crossDomain: true,
            success: function (data) {
                callback(data, null);
            },
            error: function (d) {
                callback(d, "error");
            }
        })
    }
});
export default Component;