import { Component } from "fq-foundation/Scripts/Component";
import "fq-feature/Slider/src/Scripts/main";

new Component().create("Slider", {
    element: ".f-slider",
    sliderElement: ".f-slide__wrapper",
    socialOptions: {
        arrows: "blue",
        responsive: {
            "319": {
                arrows: "blue"
            },
            "767": {
                arrows: "blue"
            },
            "1023": {
                arrows: "blue"
            },
            "1215": {
                arrows: "blue"
            },
            "1407": {
                arrows: "blue"
            }
        }
    },
    init: function () {
        var self = this;
        $(this.element).each(function () {
            var curEl = $(this);

            var options = JSON.parse(JSON.stringify($(this).data('slick-settings')));
            options = typeof options == "string" ? {} : options;
            options.appendDots = undefined;
            if ($(this).hasClass("f-social-slider")) {
                options.arrows = "blue";
                options.responsive.forEach(element => {
                    element.settings.arrows = "blue"
                });
            }
            self.setArrowAndDotsColors(options, curEl);
            $(this).find(self.sliderElement).on('init', function (event, slick) {
                $(curEl).closest(self.element).find('.f-slide').css({
                    height: "100%"
                }).find('.columns:not(.f-responsive-image)').css({
                    height: "100%"
                });
                $(window).resize(function () {
                    setTimeout(function () {
                        $(curEl).closest(self.element).find('.f-slide').css({
                            height: "100%"
                        }).find('.columns:not(.f-responsive-image)').css({
                            height: "100%"
                        });
                    }, 100);
                });
            });
            self.initialize($(this), $(this).find(self.sliderElement), options);
        });
    }
});

export default Component;